.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* Color Pallete */
:root {
	--pallete1: #6a8d73;
	--pallete2: #f4fdd9;
	--pallete3: #e4ffe1;
	--pallete4: #ffe8c2;
	--pallete5: #f0a868;
}

/* NavigationBar */
#navigation-bar {
	background-color: var(--pallete2);
}
.menu-link {
	color: var(--pallete5) !important;
}
/* remove dropdown caret */
.menu-link .nav-link::after {
	display: none !important;
}
#main-nav {
	margin-right: 4rem;
}
#main-nav a:hover {
	color: var(--pallete5);
}
#main-nav .dropdown:hover .dropdown-menu {
	display: block;
	margin-top: 0; /* remove the gap so it doesn't close */
}
@media screen and (max-width: 767px) {
	#navbar-logo {
		width: 200px;
	}
}

.home-card {
	height: 100% !important;
	box-shadow: 0px 0px 20px #c0bcbc;
	border: 2px solid #c0bcbc !important;
}
.home-card .card-body {
	background-color: var(--pallete4);
}
.home-card p {
	background-color: white;
	border: 1px solid var(--pallete3);
	padding: 0.5rem;
	border-radius: 1rem;
}
.home-card p:first-of-type {
	margin-top: 1rem;
}

/* Footer */
#main-footer {
	background-color: var(--pallete1);
	margin-top: auto;
	padding: 1rem;
	color: var(--pallete2);
}

/* Fullscreen layout with sticky footer*/
#root {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
}
.page {
	margin-top: 103px;
	flex: 1;
	min-height: 500px;
}
@media screen and (max-width: 767px) {
	.page {
		margin-top: 50px;
	}
}

.highlight-text {
	color: var(--pallete5);
	font-weight: bold;
	font-style: italic;
}

.team-card h1 {
	color: var(--pallete5);
	font-weight: bold;
	font-family: Georgia, 'Times New Roman', Times, serif;
}

.practice-area-card {
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
}

.practice-area-card ul li {
	margin-bottom: 1rem;
}

.phone-link {
	color: var(--pallete1);
	text-decoration: none;
}
.phone-link:hover {
	color: var(--pallete5);
}

h1.soft-highlight {
	font-family: 'Arial, Helvetica, sans-serif';
	font-weight: bold;
	color: #fff;
	text-shadow: var(--pallete1) 0px 0px 7px;
}
.soft-highlight {
	background-color: var(--pallete4);
	border-radius: 0rem 0rem 2rem 2rem;
}
.team-heading {
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
}
.team-card {
	box-shadow: 0px 0px 10px #ccc;
}
#home-page {
	position: relative;
	padding-top: 100px;
	padding-bottom: 100px;
}
@media screen and (max-width: 767px) {
	#home-page {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
}
#home-bg-div {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	background-image: url('../public/images/fort_myers_bg.jpeg');
	background-position: center;
	background-size: cover;
	filter: saturate(4);
	opacity: 0.6;
}
